/** @jsx jsx */
import { jsx } from 'theme-ui'
// import Hero from '../components/Hero'
// import Services from '../components/Services'
// import Models from "../components/Models"
// import ContactForm from '../components/ContactForm'
import Layout from '../components/layout'
import IncredibleJourney from '../components/IncredibleJourney'

const IndexPage = () => (
  <Layout>
    <IncredibleJourney />
  </Layout>
)

export default IndexPage
