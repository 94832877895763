import React from 'react'
import ReactCanvasConfetti from 'react-canvas-confetti'

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
}

export default class Realistic extends React.Component {
  constructor(props) {
    super(props)
    this.animationInstance = null
  }

  makeShot = (particleRatio, opts) => {
    this.animationInstance &&
      this.animationInstance({
        ...opts,
        disableForReducedMotion: true,
        ticks: 250,
        particleCount: Math.floor(200 * particleRatio),
      })
  }

  fire = () => {
    const innerWidth = Math.max(900, window.innerWidth)

    const startHeight = this.makeShot(0.25, {
      spread: 45,
      angle: 45,
      origin: { x: 0 },
      startVelocity: innerWidth * 0.05,
    })

    this.makeShot(0.25, {
      spread: 45,
      angle: 135,
      origin: { x: 1 },
      startVelocity: innerWidth * 0.05,
    })

    this.makeShot(0.15, {
      spread: 90,
      angle: 45,
      origin: { x: 0 },
      startVelocity: innerWidth * 0.03,
    })

    this.makeShot(0.15, {
      spread: 90,
      angle: 135,
      origin: { x: 1 },
      startVelocity: innerWidth * 0.03,
    })

    this.makeShot(0.1, {
      spread: 135,
      angle: 45,
      origin: { x: -0.1 },
      startVelocity: innerWidth * 0.03,
    })

    this.makeShot(0.1, {
      spread: 135,
      angle: 135,
      origin: { x: 1.1 },
      startVelocity: innerWidth * 0.03,
    })

    // this.makeShot(0.2, {
    //   spread: 60,
    //   origin: { y: 0.7 },

    // })

    // this.makeShot(0.35, {
    //   spread: 100,
    //   decay: 0.91,
    //   scalar: 0.8,
    // })

    // this.makeShot(0.1, {
    //   spread: 120,
    //   startVelocity: 25,
    //   decay: 0.92,
    //   scalar: 1.2,
    // })

    // this.makeShot(0.1, {
    //   spread: 120,
    //   startVelocity: 45,
    // })
  }

  handlerFire = () => {
    this.fire()
  }

  getInstance = instance => {
    this.animationInstance = instance
  }

  componentDidMount() {
    setTimeout(() => {
      this.fire()
    }, 500)
  }

  render() {
    return (
      <ReactCanvasConfetti
        refConfetti={this.getInstance}
        style={canvasStyles}
      />
    )
  }
}
