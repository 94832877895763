/** @jsx jsx */
import { jsx } from 'theme-ui';
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Rip = () => (
  <StaticQuery
    query={graphql`
      query {
        file: file(relativePath: { eq: "rip.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    `}
    render={data => <Img loading="lazy" fluid={data.file.childImageSharp.fluid} sx={{ maxWidth: 300, flexGrow: 1 }} />}
  />
)
export default Rip