/** @jsx jsx */
import { jsx } from 'theme-ui';
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const WeWork = () => (
  <StaticQuery
    query={graphql`
      query {
        file: file(relativePath: { eq: "wework.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1008, quality: 90) {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    `}
    render={data => <Img loading="lazy" fluid={data.file.childImageSharp.fluid} sx={{ flexGrow: 1, maxWidth: 1008, borderRadius: 5 }} />}
  />
)
export default WeWork
